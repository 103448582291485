import React from 'react';

import { ORIENTATION_TYPE, MEDIA_VIEW_TYPE } from '../types/withMediaTypes';

/*
 * isMobile
 * Returns if the browser's window width is for movile view
 * @note also this should be covered iPhoneXR landscape mode (width: 896px)
 * @return {boolean}
 */
export const isMobile = () => (
  window.innerWidth < 768
    || (window.innerWidth <= 896 && window.innerWidth > window.innerHeight)
);

/*
 * isDesktop
 * Returns if the browser's window width is for desktop view
 * @return {boolean}
 */
export const isDesktop = () => window.innerWidth >= 1280;

/*
 * isTouchDevice
 * Returns if device has touch event
 * @return {boolean}
 */
export const isTouchDevice = () => 'ontouchstart' in window;

const updateView = () => {
  const isDesktopView = isDesktop();
  const isMobileView = isMobile();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const isTabletView = !isDesktopView && !isMobileView;
  const orientation = windowWidth < windowHeight
    ? ORIENTATION_TYPE.portrait
    : ORIENTATION_TYPE.landscape;
  let viewName = isMobileView
    ? MEDIA_VIEW_TYPE.mobile
    : MEDIA_VIEW_TYPE.desktop;
  if (isTabletView) {
    viewName = MEDIA_VIEW_TYPE.tablet;
  }
  return {
    isDesktopView,
    isMobileView,
    isTabletView,
    orientation,
    viewName,
    windowHeight,
    windowWidth,
  };
};

const media = WrappedComponent => class withMedia extends React.Component {
    state = updateView();

    componentDidMount() {
      window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    onResize = () => this.setState(updateView());

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
};

export default media;
