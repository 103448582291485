import PropTypes from 'prop-types';

export const MEDIA_VIEW_TYPE = {
  desktop: 'desktop',
  mobile: 'mobile',
  tablet: 'tablet',
};

export const ORIENTATION_TYPE = {
  landscape: 'landscape',
  portrait: 'portrait',
};

const withMediaTypes = {
  isDesktopView: PropTypes.bool.isRequired,
  isMobileView: PropTypes.bool.isRequired,
  isTabletView: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(Object.values(ORIENTATION_TYPE)).isRequired,
  viewName: PropTypes.oneOf(Object.values(MEDIA_VIEW_TYPE)).isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default withMediaTypes;
