import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TEXT_TYPE } from '../../types/textTypes';
import withMediaTypes from '../../types/withMediaTypes';
import withMedia from '../../hoc/withMedia';

import Button from '../Button';
import Logo from '../Logo';
import Text from '../Text';

import './AgeGate.scss';

class AgeGate extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
    isHidden: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showNo: false,
    };
  }

  onClickYes = () => {
    const { onSelect } = this.props;
    onSelect();
  };

  onClickNo = () => {
    this.setState({
      showNo: true,
    });
  };

  onClickBack = () => {
    this.setState({
      showNo: false,
    });
  };

  render() {
    const { showNo } = this.state;
    const { isHidden, windowHeight } = this.props;

    let videoSrc;

    if (window.innerWidth > 1024) {
      videoSrc = '/video/age-gate/age-gate-desktop.mp4';
    } else if (window.innerWidth >= 768) {
      videoSrc = '/video/age-gate/age-gate-tablet.mp4';
    } else {
      videoSrc = '/video/age-gate/age-gate-mobile.mp4';
    }

    return (
      <article
        className={classNames('AgeGate', isHidden && 'is-hidden')}
        style={{
          height: windowHeight, // - (isMobile ? 10 : 12),
          position: 'absolute',
          top: 0,
        }}
      >
        <div
          className={`AgeGate--container container ${!showNo ? 'invert' : ''}`}
        >
          <div className="AgeGate--logo">
            <Logo className={!showNo ? 'invert' : ''} animate />
          </div>
          {showNo && (
            <Fragment>
              <video autoPlay playsInline loop muted key={videoSrc}>
                <source
                  src={videoSrc}
                  type="video/mp4"
                  poster="/video/hero-poster.jpg"
                />
                <track kind="captions" />
              </video>
              <Text
                className="AgeGate--back-copy"
                size={TEXT_TYPE.h3}
                tag="div"
                color="white"
                value="Looks like you're not ready for this ride yet."
              />
              <Button
                className="AgeGate--back-cta"
                isTransparent
                hasBorder
                color="white"
                onClick={this.onClickBack}
              >
                Back
              </Button>
            </Fragment>
          )}
          {!showNo && (
            <Fragment>
              <Text
                className="AgeGate--desc"
                size={TEXT_TYPE.h3}
                tag="div"
                value="It's always better to check."
              />
              <Text
                className="AgeGate--question"
                size={TEXT_TYPE.h3}
                tag="div"
                value="Are you 21 or over?"
              />
              <div className="AgeGate--switch">
                <Button
                  className="AgeGate--switch-no"
                  isTransparent
                  hasBorder
                  onClick={this.onClickNo}
                >
                  No
                </Button>
                <Button
                  className="AgeGate--switch-yes"
                  isTransparent
                  hasBorder
                  onClick={this.onClickYes}
                >
                  Yes
                </Button>
              </div>
            </Fragment>
          )}
        </div>
      </article>
    );
  }
}

export default withMedia(AgeGate);
