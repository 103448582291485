import PropTypes from 'prop-types';

import { COLOR_TYPE } from './colorTypes';

export const TEXT_TYPE = Object.freeze({
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body: 'body',
  legal: 'legal',
});

export const textDefaultProps = {
  className: null,
  color: null,
  hasSplit: false,
  onClick: null,
  size: TEXT_TYPE.body,
  tag: 'span',
  value: '',
  widows: 3,
};

const textTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(COLOR_TYPE)),
  hasSplit: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(TEXT_TYPE)),
  tag: PropTypes.string,
  value: PropTypes.string,
  widows: PropTypes.number,
};

export default textTypes;
