import { TweenMax, Power2 } from 'gsap/TweenMax';

export default class FadeInManager {
  constructor() {
    if (!window) return;
    if (window.SelectFadeInManager) return;
    window.SelectFadeInManager = this;
    this.checkInterval = 0;
    this.startListening();
  }

  startListening = () => {
    this.findUnmanaged();
    window.addEventListener('scroll', this.onScroll);
    this.checkInterval = setInterval(() => { this.findUnmanaged(); }, 250);
  }

  findUnmanaged = () => {
    const unmanaged = document.querySelectorAll('.fim:not([fim])');
    unmanaged.forEach(this.manage);
    this.onScroll();
  }

  manage = (element) => {
    element.setAttribute('fim', '');
    TweenMax.set(element, { autoAlpha: 0, y: 10 });
  }

  onScroll = () => {
    const notShownYet = document.querySelectorAll('.fim[fim]:not([fim-ready])');
    const { scrollY } = window;
    const pageBottomY = scrollY + window.innerHeight;
    notShownYet.forEach((element) => {
      const bounds = element.getBoundingClientRect(); // top, bottom
      if (pageBottomY > bounds.top) {
        this.show(element);
      }
    });
  }

  show = (element) => {
    element.setAttribute('fim-ready', '');
    const speed = element.getAttribute('fim-speed') ? Number(element.getAttribute('fim-speed')) : 0.5;
    const delay = element.getAttribute('fim-delay') ? Number(element.getAttribute('fim-delay')) : 0.5;
    TweenMax.to(element, speed, {
      autoAlpha: 1, y: 0, ease: Power2.easeIn, delay,
    });
  }
}
