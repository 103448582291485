import PropTypes from 'prop-types';

export const layoutDefaultProps = {
  headerTheme: null,
  view: null,
};

const layoutTypes = {
  children: PropTypes.node.isRequired,
  headerTheme: PropTypes.string,
  view: PropTypes.string,
};

export default layoutTypes;
