import React, { Fragment } from 'react';
import anime from 'animejs';
import Helmet from 'react-helmet';

import layoutTypes, { layoutDefaultProps } from '../../types/layoutTypes';

import AgeGate from '../AgeGate';
import Footer from '../Footer';
import Header from '../Header';

import '../../../static/fonts/fonts.scss';

import LayoutContext from './LayoutContext';

import './Layout.scss';
import FadeInManager from './FadeInManager';

const ageGateId = 'isOver21';
const expiredMS = 1000 * 60 * 60 * 24 * 30;

class Layout extends React.PureComponent {
  static propTypes = layoutTypes;

  static defaultProps = layoutDefaultProps;

  // eslint-disable-next-line react/state-in-constructor
  state = {
    headerY: null,
    mainY: null,
    isLoggedIn: null,
    showAgeGate: false,
  };

  constructor(props) {
    super(props);
    const time = window.localStorage.getItem(ageGateId);
    const isLoggedIn = time && Date.now() - Number(time) < expiredMS;
    document.body.classList.add('preload');
    if (!isLoggedIn) {
      document.body.classList.add('noScroll');
      document.body.classList.add('modal');
    }
    const templates = document.getElementById('netlify-form-templates');
    if (templates) templates.remove();

    // Init fade in manager
    // eslint-disable-next-line
    new FadeInManager();
  }

  componentDidMount() {
    // check is user is over 21
    const time = window.localStorage.getItem(ageGateId);
    const isLoggedIn = time && Date.now() - Number(time) < expiredMS;
    if (isLoggedIn) {
      setTimeout(() => {
        const ageGate = document.getElementsByClassName('AgeGate')[0];
        if (ageGate) ageGate.remove();
      }, 500);
      document.body.classList.remove('noScroll');
      document.body.classList.remove('modal');
    }
    setTimeout(() => {
      document.body.classList.remove('preload');
    }, 500);
    const isMobile = window.outerWidth < 768;
    this.setState({
      headerY: isLoggedIn ? '0' : `calc(100vh - ${isMobile ? '10' : '12'}px)`,
      mainY: isLoggedIn ? '0' : 'calc(100vh)',
      isLoggedIn: !!isLoggedIn,
      showAgeGate: !isLoggedIn,
    });
  }

  activate = () => {
    window.localStorage.setItem(ageGateId, Date.now());
    setTimeout(() => {
      document.getElementsByClassName('AgeGate')[0].remove();
    }, 1500);
    this.setState({ isLoggedIn: true });
    const { headerY, mainY } = this.state;
    const obj = { headerY, mainY };
    anime({
      duration: 1200,
      easing: 'easeInOutQuad',
      headerY: '0vh',
      mainY: '0vh',
      targets: obj,
      update: () => this.setState({
        headerY: obj.headerY,
        mainY: obj.mainY,
      }),
      complete: () => {
        document.body.classList.add('ageGateHidden');
        document.body.classList.remove('noScroll');
        document.body.classList.remove('modal');
      },
    });
  };

  static contextType = LayoutContext;

  render() {
    const {
      isLoggedIn, showAgeGate,
    } = this.state;
    if (isLoggedIn === null) {
      return null;
    }
    const { children, view } = this.props;
    return (
      <Fragment>
        <Helmet>
          <script src="https://o122.selectcannabis.com/script.js" />
        </Helmet>
        <div className={`content--container ${view}`}>
          <div
            className={`content ${view} ${
              isLoggedIn ? 'is-logged-in' : 'not-logged-in'
            }`}
          >
            {isLoggedIn && (
              <Header view={view} />
            )}
            <div className="Layout--body">
              <LayoutContext.Provider value={{ isLoggedIn }} view={view}>
                <main style={{ overflowX: 'hidden' }}>{children}</main>
              </LayoutContext.Provider>
            </div>
            {showAgeGate && (
              <AgeGate isHidden={isLoggedIn} onSelect={this.activate} />
            )}
          </div>
        </div>
        <div className={`bottom--container ${view === 'SignUpPage' ? null : view}`} />
        <Footer show={isLoggedIn} view={view} />
      </Fragment>
    );
  }
}

export default Layout;
